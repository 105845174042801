import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AttachmentService {
  headers: HttpHeaders;
  constructor(private _http: HttpClient) {
    this.headers = new HttpHeaders({
      'x-api-key': environment.apiKey
    })
  }


  download(filename: string): Observable<Blob> {
    return this.getBlob(filename).pipe(
      tap(data => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
    )
  }


  getBlob(fileName: string): Observable<Blob> {
    return this._http.get<Blob>(`${environment.urls.attachmentApi}${environment.attachmentBucketName}/informations-files/${fileName}`,
      { responseType: 'blob' as 'json', headers: this.headers })
  }


  upload(file?: File): Observable<string | undefined> {
    if (file === undefined) {
      return of(undefined);
    }
    // generating unique name for file to avoid override existing file accidentally
    const fileExtension = file?.name.split('.').pop();
    const uniqueFileName = new Date().getTime() + '.' + fileExtension;
    return this._http.put<string>(`${environment.urls.attachmentApi}${environment.attachmentBucketName}/informations-files/${uniqueFileName}`, file, { headers: this.headers }
    ).pipe(
      map(() => uniqueFileName)
    )
  }

}
